import React from 'react';
import {
  CTA,
  IGatsbyImage,
  ImageType,
  SectionBackgroundType,
} from 'utils/types';

import { DigitalResultsSection } from '../../components/DigitalResultsSection';

type ContentSection = {
  title: string;
  headline: string;
  image: IGatsbyImage;
};

export interface DigitalResultsContainerProps {
  content: {
    title: string;
    headline: string;
    subHeadline?: string;
    desktopImages: Array<ImageType>;
    mobileImages: Array<ImageType>;
    listOfContentSections: ContentSection[];
    backgroundType?: SectionBackgroundType;
    description?: {
      childMarkdownRemark?: {
        html: string;
      };
    };
    disclaimer?: string;
    // To avoid changing Contentful due to the re-architecture, this will use the Link content model from Contentful.
    cta?: CTA;
  };
  onCtaClick?: React.MouseEventHandler<HTMLButtonElement>;
}

export const DigitalResultsContainer: React.FC<
  DigitalResultsContainerProps
> = ({ content, onCtaClick }) => {
  if (!content) {
    return null;
  }

  const {
    title,
    headline,
    desktopImages,
    mobileImages,
    listOfContentSections,
    backgroundType,
    description,
    disclaimer,
    cta,
  } = content;

  const listOfContent = listOfContentSections.map((content: any) => ({
    title: content.title,
    description: content.headline,
    image: content.image.gatsbyImageData,
    imageAlt: content.image?.description,
  }));

  const props = {
    title,
    headline,
    listOfContent,
    desktopImages: desktopImages.map((image) => ({
      gatsbyImageData: image.gatsbyImageData,
      description: image.description,
    })),
    mobileImages: mobileImages.map((image) => ({
      gatsbyImageData: image.gatsbyImageData,
      description: image.description,
    })),
    backgroundType,
    description,
    disclaimer,
    cta,
    onCtaClick,
  };

  return <DigitalResultsSection {...props} />;
};

export default DigitalResultsContainer;
